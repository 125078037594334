<template>
  <div>
    <quote-status-bar
      :item="quoteStatusItem"
      :quote-status-bar="quoteStatusBarItems"
      class="ml-1"
    />
    <order-exception-status :item="orderInfo" />
    <b-row class="mt-2">
      <b-col cols="6">
        <sub-rental-dispatch :order-item="orderInfo" />
      </b-col>
      <b-col cols="6">
        <sub-rental-return :order-item="orderInfo" />
      </b-col>
    </b-row>
    <sub-rental-order-information
      class="mt-2"
      :order-item="orderInfo"
    />
    <sub-rental-order-items class="mt-2" />
    <sub-rental-form-bottom />
  </div>
</template>

<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import mainConfig from '@/views/main/warehouse/config'
import { BCol, BRow } from 'bootstrap-vue'
import OrderExceptionStatus
from '@/views/main/warehouse/main-order/components/order-exception-status/OrderExceptionStatus.vue'
import SubRentalDispatch from './SubRentalDispatch.vue'
import SubRentalReturn from './SubRentalReturn.vue'
import SubRentalOrderInformation from './SubRentalOrderInformation.vue'
import SubRentalOrderItems from './SubRentalOrderItems.vue'
import SubRentalFormBottom from './SubRentalFormBottom.vue'
import config from '../config'

export default {
  name: 'SubRentalForm',
  components: {
    OrderExceptionStatus,
    SubRentalFormBottom,
    SubRentalOrderItems,
    SubRentalOrderInformation,
    SubRentalReturn,
    SubRentalDispatch,
    QuoteStatusBar,
    BCol,
    BRow,
  },
  computed: {
    orderInfo() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    quoteStatusItem() {
      return this.orderInfo ? {
        state: this.orderInfo.fulfillment_state,
        status: this.orderInfo.fulfillment_status,
      } : {}
    },
    quoteStatusBarItems() {
      return this.orderInfo && this.quoteStatusItemsMerge(this.orderInfo.states, false)
    },
  },
  setup() {
    const { MODULE_NAME } = config()
    const { quoteStatusItemsMerge } = mainConfig()

    return {
      MODULE_NAME,
      quoteStatusItemsMerge,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
