<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t(`Fulfillment ${(parentId && children_suffix) ? parentId + "-" + children_suffix : ''} for Order`) }} {{ order_no }}
      </h3>
    </portal>
    <skeleton v-if="isLoading" />
    <sub-rental-form
      v-if="!isLoading && orderItem"
    />
  </div>
</template>

<script>
import store from '@/store'
import subRentalModule from '@/store/main/warehouse/pick/sub-rental'
import { onUnmounted } from '@vue/composition-api'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import Skeleton from './components/Skeleton.vue'
import SubRentalForm from './components/Form.vue'
import config from './config'

export default {
  name: 'SubRental',
  components: { SubRentalForm, Skeleton },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    order_no() {
      return this.orderItem?.order_no || '-'
    },
    parentId() {
      return this.orderItem?.parent_id
    },
    children_suffix() {
      return this.orderItem?.children_suffix
    },
    isLoading() {
      return this.$store.state[this.MODULE_NAME].isLoading
    },
  },
  mounted() {
    this.getOrderItem(this)
  },
  methods: { getValueFromGivenObjectByKey },
  setup() {
    const { MODULE_NAME, getOrderItem } = config()
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, subRentalModule)
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME, getOrderItem,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
