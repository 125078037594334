var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white px-1 rounded-lg",attrs:{"id":"order-items"}},[_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.$t('Order Items'))+" ")]),_c('scan',{attrs:{"asset-item-serial-numbers":_vm.assetItemSerialNumbers},on:{"getValue":_vm.handleCheckScan}}),_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"}),_c('l-table-list-collector',{ref:"lTableRef",attrs:{"table-columns":_vm.tableColumns,"module-name":_vm.MODULE_NAME,"fetched-data":_vm.assetsItem,"isSearchable":false,"is-fullfillment":true,"row-active":{
      filedKey: 'pick_at',
      classes: 'highlightRow'
    }},scopedSlots:_vm._u([{key:"head(action)",fn:function(){return [_c('div',{staticClass:"d-flex p-0"},[_c('b-form-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate && !_vm.isCheckedAllAssetItems,"checked":_vm.isCheckedAllAssetItems},on:{"change":_vm.handleCheckAllItems}})],1)]},proxy:true},{key:"cell(action)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"d-flex pl-1"},[_c('b-form-checkbox',{attrs:{"checked":item.checked},on:{"change":function($event){item.checked = !item.checked}}})],1)]}},{key:"cell(state)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"d-flex pl-1"},[_c('state-of-asset',{attrs:{"data":item,"with-label":false}})],1)]}},{key:"cell(sku)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'product.sku'))+" ")]}},{key:"cell(name)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'product.name'))+" ")]}},{key:"cell(location)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'location'))+" ")]}},{key:"cell(asset_id)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',[_c('sub-rental-assets',{attrs:{"item":item}})],1)]}},{key:"cell(pick_at)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"pl-1"},[_c('b-form-checkbox',{attrs:{"checked":Boolean(item.pick_at)},on:{"change":function($event){item.pick_at = item.pick_at ? null : new Date().toString()}}})],1)]}},{key:"cell(inspected_outbound)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"pl-1"},[_c('b-form-checkbox',{attrs:{"disabled":!item.pick_at,"checked":!!item.inspected_outbound},on:{"change":function($event){item.inspected_outbound = item.inspected_outbound ? null : true}}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }