<template>
  <div class="d-flex mt-2 pb-1 justify-content-between">
    <div class="d-flex">
      <b-button
        class="cancelBtn font-medium-1 font-weight-bolder"
        variant="outline-primary"
        @click="$router.push({ name: 'home-warehouse-view' })"
      >
        {{ $t("Back to List") }}
      </b-button>
      <b-button
        variant="secondary"
        class="font-medium-1 font-weight-bold px-3 d-flex align-items-center justify-content-center ml-2"
        :disabled="!isSomeChecked"
        @click="handleSplit"
      >
        <span>{{ $t("Request Split Order") }}</span>
      </b-button>
    </div>
    <div class="d-flex">
      <b-button
        variant="secondary"
        class="font-medium-1 font-weight-bold px-3 d-flex align-items-center justify-content-center ml-2"
        @click="printContent()"
      >
        <feather-icon
          icon="LPrintIcon"
          style="margin-right: 10px"
          size="20"
        />
        <span>{{ $t("Print Pick List") }}</span>
      </b-button>

      <b-button
        variant="success"
        class="d-flex justify-content-center ml-2"
        style="width: 170px; height: 40px"
        :disabled="!allPickedInspected || isLoading"
        @click="handleSubmit"
      >
        <feather-icon
          icon="LPickIcon"
          style="margin-right: 10px"
          size="20"
        />
        <span>{{ $t('Pick') }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { assetStates } from '@/enum/asset-statuses'
import { printContent } from '@/views/main/orders/components/helpers'
import config from '../config'

export default {
  name: 'RentalSalesFormBottom',
  components: { BButton },
  data() {
    return {
      isLoading: false,
      id: this.$route.params.id,
    }
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    assetsItem() {
      return this.orderItem.order_items
    },
    isSomeChecked() {
      return this.assetsItem.some(i => i.checked && i.pick_at)
    },
    allPickedInspected() {
      return this.assetsItem.every(i => i.checked && i.inspected_outbound && i.pick_at)
    },
  },
  methods: {
    async handleSplit() {
      const splitItems = { order_product_ids: this.assetsItem.filter(item => item.pick_at && item.checked).map(item => item.id) }
      this.isLoading = true
      try {
        await this.$store.dispatch(`${this.MODULE_NAME}/splitOrders`, {
          id: this.id,
          payload: splitItems,
        })
        this.getOrderItem(this)
      } catch (err) {
        this.errorNotification(this, err)
      } finally {
        this.isLoading = false
      }
    },
    handleSubmit() {
      const orderType = this.orderItem.order_no.split('-')[0]
      const payload = {
        order_product_ids: this.assetsItem.filter(item => item.pick_at && item.checked && item.inspected_outbound).map(item => item.id),
        order_id: this.id,
        warehouse_notes: this.orderItem.internal_shipping_notes,
      }
      this.isLoading = true
      this.sendNotification(this,
        payload,
        `${this.MODULE_NAME}/pickUpOrder`)
        .then(() => {
          this.$router.push({
            name: 'home-warehouse-pack-order',
            params: { id: this.id, orderType },
          })
        })
        .catch(err => {
          this.errorNotification(this, err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  setup() {
    const { MODULE_NAME, getOrderItem } = config()
    return {
      printContent,
      MODULE_NAME,
      getOrderItem,
      assetStates,
    }
  },
}
</script>

<style scoped lang="scss"></style>
